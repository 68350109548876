.foto{
    width: 100vh;
}
.contenedor{
    position:relative; 
    z-index:1;
}
.contenedorDos{
    display: flex;
    justify-content: center;
}
.contenedorFoto{
    position:absolute; 
    z-index: 2;
/* 
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    text-align: center; */
    /* z-index: 3;   */
    /* min-width: 100%; */
}
.contenedorBoton{
    position: absolute; 
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    z-index: 3;  
    min-width: 100%;
    height: calc(100vh - 35vh);
}