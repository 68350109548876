.video{
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.videoMovil{
    position: fixed;
    /* width:100%; */
    height: 100vh;
    /* max-width: 800px;
    min-height: 50%; */
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.contenedor{
    position:relative; 
    z-index:1;
}
.contenedorVideo{
    position:absolute; 
    z-index:2
}
.contenedorLogin{
    position:absolute; 
    display: flex;    
    flex-direction: column; 
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    z-index:3;  
    min-width: 100%;
    height: 100vh;
}